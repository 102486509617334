import { Router } from "@angular/router";
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataService, UtilsService } from "@core/services";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorComponent {
  loginForm: any;
  twoFactorForm: FormGroup;

  constructor(public dialog: MatDialog, private dataService: DataService,
              private authService: AuthService, private router: Router,
              private utils: UtilsService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loginForm = this.data.loginForm;
    this.twoFactorForm = new FormGroup({
      verify_code: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      if (this.utils.isDesktop()) {
        this.router.navigateByUrl('/live');
      } else {
        this.router.navigateByUrl('/reservations');
      }
    }
  }

  onSubmit(): void {
    if (this.loginForm != undefined && this.twoFactorForm != undefined) {
      const payload = {
        password: this.loginForm.get('password')?.value,
        phone_number: this.loginForm.get('phone')?.value,
        verify_code: this.twoFactorForm.get('verify_code')?.value
      };
      this.dataService.post('admin/login', payload).then((data) => {
        if (data.status_code === 200 && data.data.type !== 'DRIVER') {
          this.authService.setUserData(data.data);
          this.utils.saveAuthToken(data.token, data.refresh);
          this.dialog.closeAll();
          if (this.utils.isMobile()) {
            this.router.navigate(['/reservations']);
          } else {
            this.router.navigate(['/live']);
          }
        } else {
          if (data.data.type === 'DRIVER') {
            this.utils.openSnackBar('Admin users only', 5000, 'error');
          } else {
            this.utils.openSnackBar(data.data.message, 5000, 'error');
          }
        }
      });
    }
  }
}
