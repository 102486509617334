import { FormBuilder } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { ChatV2Service } from '@core/services/chat-v2/chat-v2.service';
import { IChatItem } from '@shared/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-driver-chat-list',
  templateUrl: './driver-chat-list.component.html',
  styleUrl: './driver-chat-list.component.scss'
})
export class DriverChatListComponent implements OnInit, OnDestroy {
  chats: null | IChatItem[] = null;
  filteredChats: null | Partial<IChatItem[]> = null;
  searchCtrl = this.fb.nonNullable.control('');
  @ViewChild(CdkVirtualScrollViewport) viewPort!: CdkVirtualScrollViewport;
  filterApplied = signal(false);
  private _subscription!: Subscription;

  constructor(private chatService: ChatV2Service, private fb: FormBuilder) {
  }

  ngOnInit() {
    this._subscription = this.chatService.chats$.subscribe(data => {
      if (this.chats && data && this.chats[0].driverId !== data[0].driverId && !this.filterApplied()) {
        this.viewPort.scrollToIndex(0, 'smooth');
      }
      this.chats = data;
    });
  }

  searchDrivers() {
    if (!this.searchCtrl.value.trim()) {
      this.filteredChats = null;
      this.filterApplied.set(false);
      return;
    }
    this.filteredChats = null;
    this.filterApplied.set(true);
    return this.chatService.searchDrivers(this.searchCtrl.value).subscribe(data => {
      this.filteredChats = data;
    });
  }

  trackChatBy(index: number, item?: IChatItem) {
    return item?.driverId ?? index;
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
