export type TDriverType = 'COV' | 'CONTRACT99';
export type TDriverGender = 'MALE' | 'FEMALE' | 'OTHER';

export interface IDriverExperience {
  id: number;
  type: string;
  no_experience: number;
  no_experience_older: number;
  description: string;
}

export interface IDriverOutline {
  id: number;
  answer: boolean;
  outline: number;
  driver: number;
}

export interface IBankDetails {
  bank_name: string;
  account_number: string;
  routing_number: string;
  social_security_number: string;
}

export interface IDriverFile {
  id: number;
  file: {
    id: number;
    file: string;
  },
  type: string;
  driver: number;
  expire_date: string;
}

export class DriverInfoModel {
  available: string[] = [];
  bank_details: IBankDetails = {} as IBankDetails;
  experiences: IDriverExperience[] = [];
  outlines: IDriverOutline[] = [];
  files: IDriverFile[] = [];
  referrer: string = "";
  referrer_description: string = "";
  vehicle_type: string = "";
  postal_code: string = "";
  address: string = "";
  gender: TDriverGender | undefined;
  driver_type: TDriverType | undefined;

  constructor(data: any) {
    if (data) {
      this.available = data.available;
      this.bank_details = data.bank_details;
      this.experiences = data.experiences;
      this.files = data.files;
      this.outlines = data.outlines;
      this.referrer = data.referrer;
      this.referrer_description = data.referrer_description;
      this.vehicle_type = data.vehicle_type;
      this.postal_code = data.postal_code;
      this.address = data.address;
      this.gender = data.gender;
      this.driver_type = data.driver_type;
    }
  }
}
