@if (_loading) {
    <div class="chat-item">
        <div class="chat-item__avatar pulse"></div>
        <div class="chat-item__details">
            <div class="flex-justify-between mb-2">
                <h3 class="mb-0 w-7 pulse"></h3>
                <span class="w-3 pulse"></span>
            </div>
            <div class="last-message pulse">
            </div>
        </div>
    </div>
} @else if (driver) {
    <div class="chat-item" (click)="openDriverChat()" [class.opened]="(openedChat$|async)?.id === driver.id">
        <img class="chat-item__avatar" [class.border-none]="!driver.image" alt="driver avatar"
             [src]="driver.image || '/assets/blank_user.png'">
        <div class="chat-item__details">
            <div class="flex-justify-between">
                <h3 class="mb-0 font-medium">{{ driver?.first_name }} {{ driver.last_name }}</h3>
                @if (chat.date) {
                    <span class="chat-date text-xs">{{ chat.date.toMillis() | dayDate: 'M/dd/yyyy. HH:mm' }}</span>
                }
            </div>
            @if (chat.sender_id && chat.date) {
                <div class="last-message" [class.unread]="!chat.read || chat.date.toMillis() > chat.read.toMillis()">
                    <span class="sender">{{ chat.sender_name }}</span>:&nbsp;{{ chat.message }}
                </div>
            }
        </div>
    </div>
}

<mat-divider class="my-2"/>
