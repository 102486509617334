export const environment = {
  production: false,
  api_url: 'https://envoy-staging.radixtechs.net/',
  image_url: 'https://envoy-staging.radixtechs.net/',
  main_websitelink: 'admin.radixtechs.net/',
  cookie_https: false,
  cookie_domain: '.radixtechs.net',
  page_size: 20,
  protocol: 'https://',
  firestore_chat: 'chats_staging',
  firestore_reservations: 'reservations_realtime_updates_envoy_staging',
  firebase: {
    apiKey: "AIzaSyBPHgXmmLECRjLsfLXbTBGfBrIkxCeoMOM",
    authDomain: "genial-smoke-393922.firebaseapp.com",
    projectId: "genial-smoke-393922",
    storageBucket: "genial-smoke-393922.appspot.com",
    messagingSenderId: "427998001475",
    appId: "1:427998001475:web:ba9ba437edc70a9ca117f6",
    measurementId: "G-HPPXHYY7V6"
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZW52b3lhbWVyaWNhMjAyMyIsImEiOiJjbGd1aXl3OGowbTE5M21vNW9wb2Fld2Y4In0.Iih0t247hvEPX1KJ1srunQ'
  },
  ws_server:"wss://socket-staging.radixtechs.net/ws/reservations/live",
  stripe_public_key: "pk_test_51PLEosI2igxIr5kLDekQk6FdR3enFymSkBTrW6COmb09RbACF1DJcNf7KStAtSPHVBaWY05pxjrBWIcABWvljP6N00NEfVz9Sc"
};
