import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ChatV2Service } from '@core/services';
import { tap } from 'rxjs/operators';

export const driverChatResolver: ResolveFn<boolean | any> = (route, state) => {
  const driverId = route.queryParamMap.get('driverId');
  if (!driverId) return true;
  const chatService = inject(ChatV2Service);
  return chatService.getDriver(driverId).pipe(tap(driver => {
    chatService.selectedDriver = driver;
  }));
};
