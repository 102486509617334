import { Component, ElementRef, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { IChatDriver, IMessage } from '@shared/models';
import { ChatV2Service } from '@core/services';
import { FormBuilder } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-driver-chat',
  templateUrl: './driver-chat.component.html',
  styleUrl: './driver-chat.component.scss'
})
export class DriverChatComponent implements OnInit, OnDestroy {
  messages: IMessage[] | undefined;
  selectedDriver!: IChatDriver | null;
  @ViewChild('chatView') chatView: ElementRef<HTMLElement> | undefined;
  msgCtrl = this.fb.nonNullable.control('');
  sending = signal(false);
  private _unsubscribe$ = new Subject();

  constructor(private chatService: ChatV2Service, private fb: FormBuilder) {
  }

  ngOnInit() {
    this.chatService.selectedDriver.pipe(takeUntil(this._unsubscribe$))
      .subscribe(data => {
        this.messages = undefined;
        this.selectedDriver = data;
      });
    this.chatService.chatMessages$.pipe(takeUntil(this._unsubscribe$))
      .subscribe(data => {
        this.messages = data;
        setTimeout(() => {
          this.scrollToLatestMessage();
        }, 300);
      });
  }

  async sendNewMessage() {
    const msg = this.msgCtrl.value;
    if (this.selectedDriver && !this.sending() && msg.trim()) {
      try {
        this.sending.set(true);
        this.msgCtrl.reset('');
        await Promise.allSettled([
          this.chatService.createChatMessage(this.selectedDriver.id, msg),
          this.chatService.updateDriverChat(this.selectedDriver.id, msg)
        ]);
      } catch (e) {
        console.error(e);
      } finally {
        this.sending.set(false);
      }
    }
  }

  createSkeleton(length: number) {
    return Array.from({ length }).map((_i, index) => index + 1);
  }

  /**
   * check difference in date between two consequent messages to know if belongs to same day or not
   * @param messageIndex
   */
  checkDateDiffs(messageIndex: number) {
    if (messageIndex === 0 || !this.messages) return true;
    const _currentDate = new Date(this.messages[messageIndex].date.toMillis());
    const _prevDate = new Date(this.messages[messageIndex - 1].date.toMillis());

    // Clear the time portion from the dates for accurate comparison
    _prevDate.setHours(0, 0, 0, 0);
    _currentDate.setHours(0, 0, 0, 0);

    const timeDiff = _currentDate.getTime() - _prevDate.getTime();
    return timeDiff > 0;
  }

  ngOnDestroy() {
    this.chatService.selectedDriver = null;
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }

  /**
   * Scroll the chat view to the latest message
   * @private
   */
  private scrollToLatestMessage() {
    if (this.chatView && this.chatView.nativeElement.scrollHeight > this.chatView.nativeElement.clientHeight) {
      this.chatView.nativeElement.scrollTop = this.chatView?.nativeElement.scrollHeight;
    }
  }
}
