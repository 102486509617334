<div class="system-search">
    <mat-form-field appearance="outline" class="w-full">
        <input placeholder="What are you looking for? any info within the system is a several clicks away."
               name="search" matInput [formControl]="searchCtrl">
        <span matSuffix>
            Ctrl+Shift+f
            <mat-icon class="opacity-50" matSuffix svgIcon="search_normal_icon"></mat-icon>
        </span>
    </mat-form-field>
    @if (searchCtrl.value) {
        <div class="search-results">
            @for (item of searchItems; track item) {
                <div class="search-item" (click)="search(item.url)">
                    <h4>{{ searchCtrl.value }}</h4>
                    <span class="category-item">
                        {{ item.name }}
                        <mat-icon style="width: 20px; height: 20px" [svgIcon]="item.icon"/>
                    </span>
                </div>
            }
        </div>
    } @else {
        <div class="search-placeholder p-3">
            <img alt="no result found" src="/assets/icons/no-result-system.svg"/>
            <h4>No search results</h4>
            <p>Enter text in search box to search in trips, drivers, customers, corporates and companies</p>
        </div>
    }
</div>
