import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, switchMap, take, throwError } from 'rxjs';
import { AuthService } from "@modules/auth/services/auth.service";
import { UtilsService } from '@core/services';
import { filter } from "rxjs/operators";

@Injectable()
export class GeneralInterceptor implements HttpInterceptor {
  private isRefresh = false;
  tokenSubject = new BehaviorSubject<string | null>(null);

  constructor(private utils: UtilsService, private auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let apiUrl = this.utils.getAPIServerUrl();
    if (req.url.indexOf('svg') > -1) {
      apiUrl = this.utils.protocol() + this.utils.mainWebsite();
    }
    if (req.url.includes('googleapis')) {
      return next.handle(req);
    }
    const apiReq = this.setHeaders(req.clone({
      url: apiUrl + `${req.url}`
    }));
    return next.handle(apiReq).pipe(catchError((error, caught) => {
      if (error.status === 401 && error instanceof HttpErrorResponse) {
        return this.handleAuthorizationError(apiReq, next);
      }
      return throwError(() => error);
    }));
  }

  private handleAuthorizationError(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefresh) {
      this.isRefresh = true;
      this.tokenSubject.next(null);
      return this.auth.refreshToken().pipe(
        switchMap((res) => {
          this.isRefresh = false;
          this.utils.saveAuthToken(res.access);
          this.tokenSubject.next(res.access);
          return next.handle(this.setHeaders(req));
        }),
        catchError((err) => {
          this.isRefresh = false;
          this.auth.logout();
          return throwError(err);
        })
      );
    }
    return this.tokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token => next.handle(this.setHeaders(req)))
    );
  }

  private setHeaders(req: HttpRequest<any>) {
    let isLogin = false;
    const token = localStorage.getItem('token');
    if (req.url.indexOf('login') !== -1 && token) {
      isLogin = true;
    }
    if (req.url.includes('token/refresh') && token) {
      isLogin = true;
    }
    if (!isLogin && token) {
      return req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }
    return req;
  }
}
