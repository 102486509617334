import { Router } from "@angular/router";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, throwError } from "rxjs";
import { UtilsService } from "@core/services"
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user$ = new BehaviorSubject<User | null>(null);

  constructor(private http: HttpClient, private utils: UtilsService, private router: Router) {
  }

  get userData() {
    return this.user$.asObservable();
  }

  public login(username: string, password: string): Promise<any> {
    return this.http.post('login', { username, password })
      .toPromise()
      .catch(this.handleError);
  }

  logout() {
    this.utils.clearAuth();
    this.user$.next(null);
    return this.router.navigate(['/home']);
  }

  isAuthenticated() {
    const refreshToken = localStorage.getItem('refresh')
    return this.utils.isValidToken(refreshToken);
  }

  getUserData(): any {
    return this.user$.getValue();
  }

  setUserData(currentUser: User) {
    this.user$.next(new User(currentUser));
    localStorage.setItem('userId', JSON.stringify(currentUser.id));
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('refresh');
    if (this.utils.isValidToken(refreshToken)) {
      return this.http.post<{ access: string }>('api/token/refresh', {
        refresh: refreshToken
      });
    } else {
      return throwError(() => new Error('Invalid or Expired Token'));
    }
  }

  /**
   * Initialize the authentication while app initialization to check for token expiry
   * and generate a new token if expired or logout if refresh token also expired
   */
  async initializeAuthUser(): Promise<string | null> {
    const token = localStorage.getItem('token');
    if (this.utils.isValidToken(token)) {
      return localStorage.getItem('userId');
    }
    const refreshToken = localStorage.getItem('refresh');
    if (this.utils.isValidToken(refreshToken)) {
      const refresh = await this.refreshToken().toPromise();
      if (refresh) {
        this.utils.saveAuthToken(refresh?.access);
        return localStorage.getItem('userId');
      }
    }
    return null;
  }

  private handleError(error: any): any {
    if (error.status === 401) {
    }
    return { hasError: true, 'status-code': error.status, error: error.error };
  }
}
